import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Dynamically import all Stimulus controllers within the controllers directory
const controllers = import.meta.glob("./**/*_controller.js", { eager: true })

for (const path in controllers) {
  const controller = controllers[path].default
  const controllerName = path
    .replace("./", "")
    .replace(/_/g, "-")
    .replace(/\/\//g, "-")
    .replace(/\.js$/, "")
    .replace(/\//g, "--")
  application.register(controllerName, controller)
}
